import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import Forms from '../_app/cuchillo/forms/FormValidator';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { KoraButtons } from '../components/KoraButtons';
import { KoraCalendars } from '../components/KoraCalendars';
import { GetBy } from '../_app/cuchillo/core/Element';
import Header from '../_app/cuchillo/layout/Header';

export default class Default extends Page {
  _stickyBar;
  _stickyOffset;
  _stickyPos = 0;

  constructor() {
    super();

    Videos.init();
    Acordions.init();
    KoraCalendars.init();
    Forms.init();

    //this._stickyBar = GetBy.class('__sticky', this.container)[0];
    //this.checkSticky();
  }

  checkSection() {
    const urlObj = new URL(window.location.href);
    const paramValue = urlObj.searchParams.get('section');
    if (paramValue === 'calendar') Scroll.gotoAnchor('CalendarSection');
    if (paramValue === 'experience') Scroll.gotoAnchor('BookExperience');
    if (paramValue === 'space') Scroll.gotoAnchor('BookSpace');
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: false, multiplicator: 1 });
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    /*if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {*/
    Wrap.show(() => { this.afterShow() });
    //}
  }

  async afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();

    this.checkSection();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
    KoraButtons.dispose();
    KoraCalendars.dispose();
    Forms.dispose();
  }

  checkSticky() {
    if (!this._stickyBar) return;

    if (window.scrollY >= this._stickyOffset) {
      this._stickyBar.classList.add('--sticky');
    } else {
      this._stickyBar.classList.remove('--sticky');
    }
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
    KoraButtons.resize();
    KoraCalendars.resize();
    Forms.resize();

    if (this._stickyBar) {
      this._stickyBar.classList.remove('--sticky');
      this._stickyOffset = this._stickyBar.getBoundingClientRect().top;
      //this._stickyBar.style.setProperty('--bar-height', 'auto');
      //this._stickyBar.style.setProperty('--bar-height', `${this._stickyBar.getBoundingClientRect().height}px`);
    }
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
      this.checkSticky();
    }
  }
}

ControllerPage._addPage('default', Default);
